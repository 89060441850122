<template>
  <div class="sidebar" v-if="showList.length > 0 && !route.meta.hidden">
    <div class="parent_route">{{ parentRouteTitle }}</div>
    <template v-for="(item, i) in showList">
      <!-- 当前路由 -->
      <div
        v-if="route.path == item.path"
        :key="i"
        class="sidebar_item_selected"
      >
        <img
          :src="item.meta.iconTwo"
          v-if="item.meta.iconTwo"
          class="sidebar_item_icon"
        />
        <span>{{ item.meta.title }}</span>
      </div>
      <!-- 其他路由 -->
      <div
        v-else
        :key="i"
        :class="{ sidebar_item: true }"
        @click="$router.push(item.path)"
      >
        <img
          :src="item.meta.iconOne"
          v-if="item.meta.iconOne"
          class="sidebar_item_icon old_icon"
        />
        <img
          :src="item.meta.iconTwo"
          v-if="item.meta.iconTwo"
          class="sidebar_item_icon new_icon"
        />
        <span>{{ item.meta.title }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "sidebar",
  data() {
    return {
      showList: [],
      parentRouteTitle: "",
    };
  },
  computed: {
    routes() {
      return this.$router.options.routes;
    },
    route() {
      return this.$route;
    },
  },
  watch: {
    route: {
      immediate: true,
      handler() {
        let route = this.route;
        let res = [];
        let arr = [];
        this.routes.forEach((item) => {
          if (item.meta.type == route.meta.type) {
            this.parentRouteTitle = item.meta.title;
            res = JSON.parse(JSON.stringify(item.children));
          }
        });
        res.forEach((item) => {
          if (!item.meta.isAlone && !item.meta.hidden) {
            arr.push(item);
          }
        });
        this.showList = arr;
      },
    },
  },
};
</script>

<style scoped>
.sidebar {
  width: 190px;
  /* margin-right: 21px; */
  padding: 0 20px;
  flex-shrink: 0;
}
.parent_route {
  color: #1b6ed5;
  font-size: 18px;
  margin: 16px 0;
  text-align: center;
  margin-bottom: 15px;
}
.sidebar_item {
  height: 34px;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a4a4a;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
}
.sidebar_item_selected {
  height: 34px;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  background-color: #1b6ed5;
  margin-bottom: 10px;
  cursor: pointer;
}
.sidebar_item_icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.sidebar_item:hover {
  background-color: #1b6ed5;
  color: #fff;
}
.old_icon{
  display: block;
}
.new_icon{
  display: none;
}
.sidebar_item:hover .old_icon {
  display: none;
}
.sidebar_item:hover .new_icon {
  display: block;
}
</style>
