import { login, loginByTel, logout, getUserInfo } from "@/api/login";
import router from "@/router";

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();

const mutations = {};

const actions = {
  async login({ commit }, loginForm) {
    let res = await login(loginForm);
    return new Promise((resolve, reject) => {
      if (res.code == "0") {
        sessionStorage.setItem("match_token", res.data);
        sessionStorage.removeItem("match_role_path");
      }
      resolve(res);
    });
  },
  async loginPhone({ commit }, loginForm) {
    let res = await loginByTel(loginForm);
    return new Promise((resolve, reject) => {
      if (res.code == "0") {
        sessionStorage.setItem("match_token", res.data);
        sessionStorage.removeItem("match_role_path");
      }
      resolve(res);
    });
  },

  // user logout
  async logout({ commit, state }) {
    let res = await logout();
    return new Promise((resolve, reject) => {
      if (res.code == "0") {
        sessionStorage.clear();
        router.go(0);
      }
      resolve(res);
    });
  },
  async getUserInfo({ commit, state }) {
    let res = await getUserInfo();
    return new Promise((resolve, reject) => {
      if (res.code == "0") {
        let userInfo = JSON.stringify(res.data);
        sessionStorage.setItem("match_userInfo", userInfo);
      }
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
