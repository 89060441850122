import { getRouteList } from "@/api/login";
const state = {
  asyncRoute: [], //是否动态挂载的标识---存在vuex，刷新后失效，动态获取后也可以存值，正好可以记录是否动态挂载
};

const mutations = {
  SET_ASYNC_ROUTE: (state, route) => {
    state.asyncRoute = route;
    sessionStorage.setItem("match_role_path", JSON.stringify(route));
  },
  RESET_ASYNC_ROUTE: (state) => {
    state.asyncRoute = [];
  },
};

const actions = {
  // 根据路由权限表，结合路由总表生成可访问的路由表
  async getAsyncRoute({ commit }) {
    let res = await getRouteList();
    return new Promise((resolve, reject) => {
      if (res.code == "0") {
        let routerList = res.data.map((item) => {
          return item.url;
        });
        commit("SET_ASYNC_ROUTE", routerList);
        resolve();
      } else {
        reject();
      }
    });
  },
  resetAsync({ commit }) {
    commit("RESET_ASYNC_ROUTE");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
