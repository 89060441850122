
export const global = {
  supervisionLevelData: {
    1:'常规监管',
    2:'一般监管',
    3:'较重监管',
    4:'重点监管',
  },
  matchLevelData: {
    1:'国际',
    2:'全国',
    3:'省级',
    4:'市级',
    5:'区县级',
  },
  riskLevelData: {
    1:'一般风险',
    2:'中风险',
    3:'较高风险',
    4:'高风险',
  },
  warnSourceData: {
    1:'赛场环境因素',
    2:'赛事组织因素',
    3:'其他影响因素',
    4:'专家评估',
  },
  feedTypeData: {
    0:'未处理',
    1:'已执行',
    2:'风险处理方案',
    3:'专家评估',
    4:'已上报预警',
  },
}