const state = {
  metaInfo: {},
};

const mutations = {
  CAHNGE_META_INFO(state, metaInfo) {
    state.metaInfo = metaInfo;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
