import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/components/layout/index";

Vue.use(VueRouter);

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject)
//     return originalPush.call(this, location, onResolve, onReject);
//   return originalPush.call(this, location).catch((err) => err);
// };

const constantRoutes = [
  {
    path: "/",
    redirect: "/homePage",
    // component: () => import("@/components/layout"),
    component: Layout,
    meta: {
      type: "homePage", //用于顶部菜单的高亮
    },
    children: [
      {
        path: "/homePage",
        component: () => import("@/views/homePage/homePage.vue"),
        meta: {
          title: "首页",
          type: "homePage",
          isAlone: true, //是否为单独的一个页面
          keywords: "社区运动会,全民健身,体育赛事,全国社区运动会",
          description:
            "社区运动会”是由中华全国体育总会主办，中体产业集团股份有限公司运营的全国性赛事活动。社区运动会坚持线上线下结合、传统新兴并举，开展群众喜闻乐见、丰富多彩的全民健身赛事活动，丰富全民健身赛事活动供给。",
        },
      },
    ],
  },
  {
    path: "/404",
    component: () => import("@/error/404"),
    meta: {
      title: "404",
      hidden: true, //控制是否展示
    },
  },
];

const createRouter = () =>
  new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
  router.options.routes = constantRoutes;
}

export default router;
