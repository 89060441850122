import request from "@/utils/request";

//获取列表数据
export function getList(data) {
  return request({
    url: "/front/cmsCategory/getContentByCode",
    method: "post",
    // transformRequest: [
    //   function (data) {
    //     let ret = "";
    //     for (let it in data) {
    //       ret +=
    //         encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
    //     }
    //     return ret;
    //   },
    // ],
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded",
    // },
    data,
  });
}
//获取详情
export function getDetail(data) {
  return request({
    url: "/front/cmsContent/cmsContentById",
    method: "post",
    data,
  });
}
