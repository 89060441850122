import axios from "axios";
import { Message, Loading } from "element-ui";
import store from "@/store";
import router from "@/router";

// let responseFlag = false;
/** 是否为下载文件 */
let isDownloadFile = false;
/** 下载文件 */
const downloadFile = (data, headers) => {
  const contentDisposition = headers["content-disposition"]; //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
  const fileNameReg = new RegExp("filename=([^;]+\\.[^\\.;]+);*");
  const result = fileNameReg.exec(contentDisposition);
  const fileName = decodeURIComponent(result[1]);

  const blob = new Blob([data], {
    type: "application/vnd.ms-excel",
  });

  const link = document.createElement("a");
  link.style.display = "none";
  link.href = URL.createObjectURL(blob);
  link.download = fileName || "导出列表";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 15000, // request timeout
  headers: {
    type: "web",
    "Cache-Control": "no-cache", // 取消缓存
    "Content-Type": "application/json;charset=UTF-8",
  },
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // responseFlag = !!config.headers.responseCtr;

    if (sessionStorage.getItem("match_token")) {
      config.headers["token"] = sessionStorage.getItem("match_token");
    }
    if (config.isUpload) {
      config.timeout = null;
    } else {
      config.timeout = 30000;
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    if (isDownloadFile) {
      downloadFile(response.data, response.headers);
      return;
    }

    const res = response.data;
    if (res.code == "2") {
      Message({
        message: "登录超时，请重新登录",
        type: "error",
        duration: 2 * 1000,
        onClose() {
          sessionStorage.clear();
          router.go(0);
          return Promise.reject(new Error(res.message || "Error"));
        },
      });
    } else {
      return res;
    }
  },
  (error) => {
    let message = error.message;
    if (message.indexOf("500")) {
      message = "请求失败，请稍后再试，或联系系统管理员";
    } else if (message.indexOf("400")) {
      message = "系统错误(400)，请联系系统管理员";
    } else if (message.indexOf("404")) {
      message = "系统错误(404)，请联系系统管理员";
    }
    Message({
      message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
