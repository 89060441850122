import router from "./router";
import getPageTitle from "@/utils/getPageTitle";
import { addRoute } from "@/utils/routeHandle";
import store from "@/store";
import vue from "./main.js";

router.beforeEach(async (to, from, next) => {
  let metaInfo = {
    title: to.meta.title + "-社区运动会",
    keywords: to.meta.keywords || "",
    description: to.meta.description || "",
  };
  store.commit("seo/CAHNGE_META_INFO", metaInfo);
  // document.title = getPageTitle(to.meta.title);
  let obj = {
    meta: from.meta,
    path: from.path,
  };

  let haveToken = sessionStorage.getItem("match_token"); //查看是否登录
  let userInfo = sessionStorage.getItem("match_userInfo")
    ? JSON.parse(sessionStorage.getItem("match_userInfo"))
    : ""; //查看是否获取了用户信息
  let asyncRoute = store.state.route.asyncRoute; //查看是否动态挂载了路由
  // let pathFlag = store.state.user.pathFlag;
  if (haveToken) {
    // 暂时没有这个部分
    if (!userInfo) {
      try {
        await store.dispatch("user/getUserInfo");
      } catch (error) {}
    }
    if (asyncRoute && asyncRoute.length > 0) {
      next();
    } else {
      let list = JSON.parse(sessionStorage.getItem("match_role_path"));
      let routeList = list ? list : [];
      if (routeList.length == 0) {
        await store.dispatch("route/getAsyncRoute");
        routeList = store.state.route.asyncRoute;
      } else {
        store.commit("route/SET_ASYNC_ROUTE", routeList); //本地存储
      }
      addRoute(routeList); //动态挂载

      //判断当前页面在刷新后还能否访问
      let newObj = {};
      routeList.forEach((item) => {
        newObj[item] = true;
      });
      if (newObj[to.path]) {
        next({ ...to, replace: true }); //保证所有的路由加载完成
      } else {
        next("/homePage");
      }
    }
  } else {
    if (asyncRoute && asyncRoute.length > 0) {
      //已挂载
      next();
    } else {
      //未挂载
      let routeList = [
        "/homePage",
        "/news",
        "/announcement",
        "/detail",
        "/about",
        "/wonderfulMoment",
        "/helpCenter",
        "/holdMatch",
      ];
      store.commit("route/SET_ASYNC_ROUTE", routeList); //本地存储
      addRoute(routeList); //动态挂载

      //判断当前页面在刷新后还能否访问
      let newObj = {};
      routeList.forEach((item) => {
        newObj[item] = true;
      });
      if (newObj[to.path]) {
        next({ ...to, replace: true }); //保证所有的路由加载完成
      } else {
        next("/homePage");
      }
    }
  }
});

router.afterEach(() => {});
