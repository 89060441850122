export function timeFormat(time) {
    if (!time) {
      return "";
    }
    try {
      return time.split(" ")[0];
    } catch (error) {
      return time;
    }
  }
  export default {
    timeFormat,
  };