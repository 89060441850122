<template>
  <div class="head_top">
    <div class="head_top_img">
      <div class="head_top_img_left">
        <img src="@/assets/image/head/headLogo.png" class="head_img_one" />
        <img src="@/assets/image/head/headFont.png" class="head_img_two" />
      </div>
      <div class="head_top_img_right" @click="toHoldMatch">我要办赛</div>
    </div>
    <div :class="{ head_menu: true, fixed_menu: fixedMenuFlag }" ref="menuBox">
      <template v-for="(item, i) in routes">
        <div
          :key="i"
          v-if="!item.meta.hidden"
          class="menu_item"
          @click="toMenu(item.children[0])"
        >
          <div
            :class="{
              menu_item_bgc: true,
              menu_item_bgc_sel:
                highLightPath == item.path || highLightPath == item.redirect,
            }"
          ></div>
          <div class="menu_item_name">
            {{
              item.meta.title ? item.meta.title : item.children[0].meta.title
            }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "layoutHeader",
  data() {
    return {
      highLightPath: "",
      menuBoxTop: 0, //记录菜单距离顶部的距离
      fixedMenuFlag: false, //吸顶效果是否开启
    };
  },
  computed: {
    routes() {
      return this.$router.options.routes;
    },
    nowRoute() {
      return this.$route;
    },
  },
  watch: {
    nowRoute: {
      immediate: true,
      handler() {
        let oldPath = JSON.parse(
          sessionStorage.getItem("admin_high_light_one")
        );
        // this.highLightPath = oldPath ? oldPath.path : this.nowRoute.path;
        this.highLightPath = this.nowRoute.path;
      },
    },
  },
  created() {
    let oldPath = JSON.parse(sessionStorage.getItem("admin_high_light_one"));
    this.highLightPath = oldPath ? oldPath.path : this.nowRoute.path;
    window.addEventListener("scroll", this.watchScroll);
  },
  mounted() {
    this.menuBoxTop = this.$refs["menuBox"].offsetTop;
  },
  beforeRouteLeave(to, form, next) {
    window.removeEventListener("scroll", this.watchScroll);
  },
  methods: {
    toMenu(route) {
      if (route.path != this.$route.path) {
        // 记录当前的一级菜单---详情页的面包屑展示及高亮展示
        sessionStorage.setItem("admin_high_light_one", JSON.stringify(route));
        this.$router.push(route.path);
        this.highLightPath = route.path;
      }
    },
    toHoldMatch() {
      if (this.$route.path != "/holdMatch") {
        this.$router.push("/holdMatch");
      }
    },
    watchScroll() {
      // 滚动条滚动距离
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > this.menuBoxTop) {
        this.fixedMenuFlag = true;
      } else {
        this.fixedMenuFlag = false;
      }
    },
  },
};
</script>

<style scoped>
.head_top_img {
  /* background: url(../../../assets/image/head/headBg.png) 100% center; */
  height: 0.8rem;
  display: flex;
  align-items: center;
  padding: 0 0.7rem;
  justify-content: space-between;
}
.head_top_img_left {
  display: flex;
  align-items: center;
}
.head_top_img_right {
  color: #3e3a39;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
.head_img_one {
  width: 0.6rem;
  height: 0.68rem;
  margin-right: 0.1rem;
}
.head_img_two {
  width: 4.2rem;
  height: 0.52rem;
}
.head_menu {
  height: 0.52rem;
  background-color: #3e3a39;
  color: #fefefe;
  display: flex;
  align-items: center;
  padding-left: 0.7rem;
}
.fixed_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
.menu_item {
  padding: 0 0.32rem;
  height: 100%;
  display: flex;
  margin-right: 0.3rem;
  position: relative;
  cursor: pointer;
}
.menu_item_bgc {
  background-color: #d80c24;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: skew(-30deg);
  z-index: 0;
  display: none;
}
.menu_item_bgc_sel {
  display: block;
}
.menu_item_name {
  width: 100%;
  height: 100%;
  line-height: 0.52rem;
  z-index: 1;
  text-align: center;
}
</style>
