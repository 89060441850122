import Layout from "@/components/layout/index";
export let asyncList = [
  {
    path: "/news",
    redirect: "/news",
    // component: () => import("@/components/layout"),
    component: Layout,
    meta: {
      type: "news", //用于顶部菜单的高亮
    },
    children: [
      {
        path: "/news",
        component: () => import("@/views/news/index.vue"),
        meta: {
          title: "新闻动态",
          isAlone: true, //是否为单独的一个页面
          keywords: "社区运动会,新闻动态,赛事新闻,社区体育",
          description:
            "了解社区运动会最新动态、赛事新闻、赛事动态、赛事预告等最新内容。",
        },
      },
    ],
  },
  {
    path: "/wonderfulMoment",
    redirect: "/wonderfulMoment",
    // component: () => import("@/components/layout"),
    component: Layout,
    meta: {
      type: "wonderfulMoment", //用于顶部菜单的高亮
    },
    children: [
      {
        path: "/wonderfulMoment",
        component: () => import("@/views/wonderfulMoment/index.vue"),
        meta: {
          title: "精彩瞬间",
          isAlone: true, //是否为单独的一个页面
          keywords: "运动会,精彩瞬间,社区体育,群众体育",
          description:
            "社区运动会赛事精彩瞬间合集，彰显全民健身风采，参赛选手尽情参与其中，体会体育运动的魅力",
        },
      },
    ],
  },
  {
    path: "/announcement",
    redirect: "/announcement",
    // component: () => import("@/components/layout"),
    component: Layout,
    meta: {
      type: "announcement", //用于顶部菜单的高亮
    },
    children: [
      {
        path: "/announcement",
        component: () => import("@/views/announcement/index.vue"),
        meta: {
          title: "官方公告",
          type: "announcement",
          isAlone: true, //是否为单独的一个页面
          keywords: "社区运动会,官方公告,体育总局,国家体委",
          description: "社区运动会官方公告。",
        },
      },
    ],
  },
  {
    path: "/about",
    redirect: "/about",
    // component: () => import("@/components/layout"),
    component: Layout,
    meta: {
      type: "about", //用于顶部菜单的高亮
    },
    children: [
      {
        path: "/about",
        component: () => import("@/views/about/index.vue"),
        meta: {
          title: "关于我们",
          isAlone: true, //是否为单独的一个页面
          keywords: "社区运动会,中体数科,中体产业,社区",
          description:
            "社区运动会官网由中体数科（北京）体育发展有限公司负责建设运营，电 话：010-87023220，邮 编：100176，邮 箱：qgsqydh@tech-csig.com。 中体数科（北京）体育发展有限公司（简称中体数科），是国家体育总局控股的唯一A股主板上市公司-中体产业集团（股票代码：600158），为助力体育强国、数字中国战略而组建的全资子公司。",
        },
      },
    ],
  },
  {
    path: "/helpCenter",
    redirect: "/helpCenter",
    // component: () => import("@/components/layout"),
    component: Layout,
    meta: {
      type: "helpCenter", //用于顶部菜单的高亮
    },
    children: [
      {
        path: "/helpCenter",
        component: () => import("@/views/helpCenter/index.vue"),
        meta: {
          title: "帮助中心",
          isAlone: true, //是否为单独的一个页面
          keywords: "社区运动会,操作手册,操作视频",
          description: "社区运动会平台系统使用手册，系统操作视频。",
        },
      },
    ],
  },
  {
    path: "/detail",
    redirect: "/detail",
    // component: () => import("@/components/layout"),
    component: Layout,
    meta: {
      hidden: true,
    },
    children: [
      {
        path: "/detail",
        component: () => import("@/views/detail/index.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
  {
    path: "/holdMatch",
    redirect: "/holdMatch",
    // component: () => import("@/components/layout"),
    component: Layout,
    meta: {
      hidden: true,
    },
    children: [
      {
        path: "/holdMatch",
        component: () => import("@/views/holdMatch/index.vue"),
        meta: {
          title: "我要办赛",
        },
      },
    ],
  },
];
