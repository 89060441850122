<template>
  <div class="footer">
    <div class="footer_top">
      <div class="footer_top_left">
        <div class="footer_top_item">
          <div class="footer_top_item_head">友情链接</div>
          <!-- <a href="" target="_blank" class="footer_top_item_name">主办单位官网</a> -->
          <a
            :href="item.contentUrl"
            target="_blank"
            class="footer_top_item_name"
            v-for="(item, i) in list1"
            :key="i"
            >{{ item.contentTitle }}</a
          >
        </div>
        <!-- <div class="footer_top_item">
        <div class="footer_top_item_head">帮助中心</div>
        <div class="footer_top_item_name">帮助中心</div>
      </div> -->
        <div class="footer_top_item">
          <div class="footer_top_item_head">联系方式</div>
          <div class="footer_top_item_name" v-for="(item, i) in list2" :key="i">
            {{ item.contentTitle }}：{{ item.contentDescription }}
          </div>
        </div>
      </div>
      <div class="footer_top_right">
        <img
          src="@/assets/image/head/footerCode.png"
          class="footer_top_right_img"
        />
        <div style="margin: 7px 0">微信扫描二维码</div>
        <div>添加客服</div>
      </div>
    </div>
    <div class="footer_bot">
      <div>
        中体数科（北京）体育发展有限公司 © 版权所有　
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
          >备案号：京ICP备2021023469号-5</a
        >
      </div>
      <div class="footer_bot_two">
        <img src="@/assets/image/head/recordIcon.png" class="record_icon" />
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11030102011249"
          target="_blank"
          >京公网安备11030102011249号</a
        >
      </div>
      <div>技术支持由中体数科提供</div>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/login";
export default {
  name: "layoutFooter",
  data() {
    return {
      list1: [
        // {
        //   url: "https://www.sport.gov.cn/",
        //   name: "国家体育总局",
        // },
        // {
        //   url: "https://www.sport.org.cn/",
        //   name: "中华全国体育总会",
        // },
        // {
        //   url: "http://www.olympic.cn/",
        //   name: "中国奥组委",
        // },
        // {
        //   url: "https://sports.csig158.com/",
        //   name: "中体产业",
        // },
        // {
        //   url: "https://www.tech-csig.com/",
        //   name: "中体数科",
        // },
      ],
      list2: [
        // {
        //   name: "地址",
        //   value: "北京市北京经济技术开发区荣华中路19号院1号楼 B座23层2301室",
        // },
        // {
        //   name: "电话",
        //   value: "010-87023220",
        // },
        // {
        //   name: "邮编",
        //   value: "100176",
        // },
        // {
        //   name: "电子邮箱",
        //   value: "qgsqydh@tech-csig.com",
        // },
      ],
    };
  },
  created() {
    this.getList1();
    this.getList2();
  },
  methods: {
    async getList1() {
      try {
        let res = await getList({
          categoryCode: "footer-link",
          pageSize: 7,
          pageNum: 1,
        });
        if (res.code == "0") {
          this.list1 = res.data.cmsContents.dataList;
        } else {
          this.list1 = [];
        }
      } catch (error) {}
    },
    async getList2() {
      try {
        let res = await getList({
          categoryCode: "footer-contact",
          pageSize: 7,
          pageNum: 1,
        });
        if (res.code == "0") {
          this.list2 = res.data.cmsContents.dataList;
        } else {
          this.list2 = [];
        }
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
.footer_top {
  background: url(../../../assets/image/head/footerBg.png) no-repeat;
  background-size: 100% 100%;
  height: 2.97rem;
  padding: 0.6rem 0.7rem 0 0.7rem;
  display: flex;
  justify-content: space-between;
}
.footer_top_left {
  display: flex;
  align-items: flex-start;
}
.footer_top_right {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  margin-right: 0.8rem;
}
.footer_top_right_img {
  width: 1.34rem;
  height: 1.34rem;
}
.footer_top_item {
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
}
.footer_top_item_head {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0.2rem;
  color: #fff;
}
.footer_top_item_name {
  margin-bottom: 0.1rem;
  color: #fff;
}
.footer_bot {
  height: 45px;
  background-color: #3e3a39;
  color: #c8c9c9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_bot a {
  color: #c8c9c9;
}
.footer_bot a:hover {
  text-decoration: underline;
}
.footer_bot_two {
  display: flex;
  align-items: center;
  margin-right: 76px;
  margin-left: 30px;
}
.record_icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
</style>
