import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/style/common.css";
import store from "./store";
import filterObj from "@/plugin/filters";
import "@/permission";
import "@/assets/style/search.css";
import Meta from "vue-meta";
// 使用vue-meta
Vue.use(Meta);

Vue.config.productionTip = false;
Vue.use(ElementUI);

import less from "less";
Vue.use(less);
import dayjs from "dayjs";
Vue.prototype.dayjs = dayjs;
import VideoPlayer from "vue-video-player";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
Vue.use(VideoPlayer);

// 点击 el-button以后禁用按钮一段时间，防止重复点击
/* 在 el-button 中添加 v-no-more-click */
Vue.directive("noMoreClick", {
  inserted(el, binding) {
    el.addEventListener("click", (e) => {
      el.classList.add("is-disabled");
      el.disabled = true;
      setTimeout(() => {
        el.disabled = false;
        el.classList.remove("is-disabled");
      }, 2000); //时长2秒
    });
  },
});

/* 设置部分全局字段用于后台数字匹配到对应汉字 */
import { global } from "@/utils/globalData.js";
Vue.prototype.$global = global;

let defaultImg = require("@/assets/image/page/default.jpg");
Vue.prototype.$defaultImg = defaultImg;

for (const key in filterObj) {
  Vue.filter(key, filterObj[key]);
}

let vue = new Vue({
  router,
  store,
  metaInfo() {
    return {
      title: store.state.seo.metaInfo.title,
      meta: [
        {
          name: "keywords",
          content: store.state.seo.metaInfo.keywords,
        },
        {
          name: "description",
          content: store.state.seo.metaInfo.description,
        },
      ],
    };
  },
  render: (h) => h(App),
}).$mount("#app");

var deviceWidth = document.documentElement.clientWidth;
if (deviceWidth < 1280) {
  deviceWidth = 1280;
  document.documentElement.style.fontSize = deviceWidth / 12.8 + "px";
} else {
  document.documentElement.style.fontSize = deviceWidth / 12.8 + "px";
}
window.onresize = function () {
  var deviceWidth = document.documentElement.clientWidth;
  if (deviceWidth < 1280) {
    deviceWidth = 1280;
    document.documentElement.style.fontSize = deviceWidth / 12.8 + "px";
  } else {
    document.documentElement.style.fontSize = deviceWidth / 12.8 + "px";
  }
};

export default vue;
