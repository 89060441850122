<template>
  <div class="layout">
    <layout-header class="layout_top"></layout-header>
    <div class="layout_bot">
      <!-- 侧边菜单栏 -->
      <!-- <sidebar class="layout_bot_sidebar"></sidebar> -->
      <!-- 中间面包屑和内容区域 -->
      <div class="layout_mid">
        <!-- 面包屑 -->
        <!-- <div
          v-if="route.meta.hidden && route.path != '/homePage'"
          class="thr_level_page"
        >
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>{{
              parentRoute.meta.title
            }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ route.meta.title }}</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="back_box" @click="toOldPage">
            <span class="el-icon-back turn_back_icon"></span>
            <span>返回</span>
          </div>
        </div> -->
        <router-view v-if="refreshPage" class="layout_mid_page"></router-view>
      </div>
    </div>
    <layout-Footer class="layout_footer"></layout-Footer>
    <!-- 赛事报名 -->
    <div class="match_sign">
      <img src="@/assets/image/head/signEnter.png" alt="" />
    </div>
  </div>
</template>

<script>
import layoutFooter from "./components/layoutFooter.vue";
import layoutHeader from "./components/layoutHeader.vue";
import sidebar from "./components/sidebar.vue";
export default {
  name: "layout",
  components: { layoutFooter, layoutHeader, sidebar },
  data() {
    return {
      // parentRoute: {},
      refreshPage: true,
    };
  },
  computed: {
    route() {
      return this.$route;
    },
    routes() {
      return this.$router.options.routes;
    },
  },
  watch: {
    route: {
      immediate: true,
      handler() {
        this.refreshPage = false;
        this.$nextTick(() => {
          this.refreshPage = true;
        });
        let route = this.route;
        // this.routes.forEach((item) => {
        //   if (item.meta.type == route.meta.type) {
        //     this.parentRoute = item;
        //   }
        // });
      },
    },
  },
  methods: {
    toSignUp() {
      window.open("https://regist.ccl-cityleague.com");
    },
  },
};
</script>

<style scoped>
.layout {
  background-color: #fff;
}
.layout_bot {
  display: flex;
  min-height: calc(100vh - 0.6rem);
  /* overflow: auto; */
  /* width: 100%; */
  overflow: hidden;
}
.layout_mid {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.layout_mid_page {
  flex: 1;
  background-color: #fff;
}
.two_level_page {
  height: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #e9e9e9;
  padding-left: 0.17rem;
  background-color: #fff;
}
.thr_level_page {
  height: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.back_box {
  margin-left: 0.2rem;
  color: #277fed;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.turn_back_icon {
  font-size: 16px;
  margin-right: 7px;
}

.match_sign {
  background-color: #fff;
  width: 1.35rem;
  height: 1.95rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 45px;
  right: 0;
  z-index: 99;
  box-shadow: -1px -1px 4px 0px rgba(216, 12, 36, 0.08);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.match_sign img {
  width: 0.95rem;
  height: 1.54rem;
}
</style>
