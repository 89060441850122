import { asyncList } from "@/router/pathList";
import router from "@/router";

export function getAsyncList(arr) {
  let obj = {};
  arr.forEach((item) => {
    obj[item] = true;
  });
  function digui(list) {
    let res = [];
    list.forEach((item) => {
      if (obj[item.path] && !item.children) {
        res.push(item);
      } else if (item.children) {
        let children = digui(item.children);
        if (children.length > 0) {
          item.children = children;
          res.push(item);
        } else {
          if (obj[item.path]) {
            delete item.children;
            res.push(item);
          }
        }
      }
    });
    return res;
  }
  let result = digui(asyncList);
  return result;
}

//动态挂载路由
export function addRoute(routeList) {
  let asyncPathList = getAsyncList(routeList);
  let syncRoute = router.options.routes;
  let allRoute = syncRoute.concat(asyncPathList);
  allRoute.push({
    path: "*",
    redirect: "/404",
    meta: {
      hidden: true,
    },
  });
  router.options.routes = [];

  router.options.routes = allRoute;
  router.addRoutes(allRoute);
}
